import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Імперія-Друк: Типографія та поліграфічні послуги
			</title>
			<meta name={"description"} content={"Ласкаво просимо до Імперія-Друк, де точність поєднується з мистецтвом типографіки та друку."} />
			<meta property={"og:title"} content={"Головна | Імперія-Друк: Типографія та поліграфічні послуги"} />
			<meta property={"og:description"} content={"Ласкаво просимо до Імперія-Друк, де точність поєднується з мистецтвом типографіки та друку."} />
			<meta property={"og:image"} content={"https://unitas-liss.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://unitas-liss.com/img/102264.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://unitas-liss.com/img/102264.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://unitas-liss.com/img/102264.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://unitas-liss.com/img/102264.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://unitas-liss.com/img/102264.png"} />
			<meta name={"msapplication-TileImage"} content={"https://unitas-liss.com/img/102264.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0px 0 70px 0" sm-padding="0px 0 60px 0" inner-min-width="100%" quarkly-title="About-17">
			<Override slot="SectionContent" flex-direction="column" align-items="center" />
			<Image src="https://unitas-liss.com/img/1.jpg" display="block" max-height="800px" object-fit="cover" />
			<Box
				flex-direction="row"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				display="flex"
				padding="50px 12px 54px 12px"
			>
				<Box width="20%" sm-width="100%" sm-margin="0px 0px 16px 0px">
					<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" lg-margin="10px 0px 0px 0px">
						Типографія та поліграфічні послуги
					</Text>
				</Box>
				<Box width="80%" sm-width="100%">
					<Text
						margin="0px 0px 30px 0px"
						font="normal 400 42px/1.3 --fontFamily-sansHelvetica"
						color="--dark"
						lg-font="normal 400 50px/1.3em &quot;Inter&quot;, sans-serif"
						md-font="normal 400 34px/1.3em &quot;Inter&quot;, sans-serif"
						letter-spacing="0.01rem"
					>
						Ласкаво просимо до Імперія-Друк, де точність поєднується з мистецтвом типографіки та друку. Спеціалізуючись на широкому спектрі поліграфічних послуг, ми втілюємо ваші проекти в життя з винятковою якістю та увагою до деталей.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="150px 0 150px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://unitas-liss.com/img/2.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
					/>
					<Image
						src="https://unitas-liss.com/img/3.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 56px/1.2 --fontFamily-serifGaramond"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Чому варто обрати Імперія-Друк?
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="center">
						Точна типографіка: Наш досвід у сфері типографіки гарантує, що кожна друкована продукція передаватиме ваше повідомлення ефективно та елегантно.
						<br />
						<br />
						Передові рішення для друку: Використовуючи найсучасніші технології друку, ми створюємо високоякісні відбитки, які вирізняються чіткістю та кольором.
						<br />
						<br />
						Кастомізація: Індивідуальні рішення для задоволення ваших унікальних потреб у друці, чи то для бізнесу, чи то для заходів, чи то для особистих проектів.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});